<template>
	<div class="settings-wrapper">
		<q-expansion-item
			class="text-left"
			expand-separator
			label="Email Preview"
			icon="contact_mail"
			caption="Preview how email will look when sent"
		>
			<div class="center-preview">
				<div class="preview-email">
					<div class="preview">
						<img id="email_top_image" style="max-width: 100%" />
					</div>
					<br />
					<p>
						Lorem ipsum dolor sit amet consectetur, adipisicing elit. Omnis
						minus placeat velit iste tenetur nulla ipsum aut magnam inventore
						cum consequatur, sint quasi facilis officiis dignissimos voluptates
						ullam architecto! Vel, tempore eos maiores in nesciunt quaerat fuga
						totam omnis ipsam praesentium, voluptatibus suscipit eius aut minus
						exercitationem autem rem amet, nobis ea placeat ullam quod. Eveniet,
						eligendi. Consequuntur minima, accusamus rerum quasi omnis ad id
						reprehenderit iste officiis laborum fuga perspiciatis labore
						consequatur modi. In tempora itaque inventore asperiores voluptatem,
						quae maxime velit? Provident unde reprehenderit cum nihil, atque
						quibusdam mollitia numquam illo hic exercitationem labore possimus
						vitae. Necessitatibus numquam quasi accusamus consequatur suscipit
						nihil, ipsa officia repellat odit eaque, ea, expedita nobis
						blanditiis nemo ut adipisci vero! Ut harum soluta minima iure
						voluptatem error veniam non, expedita dignissimos iste molestias
						magni quidem eligendi repellendus, a nostrum eos quaerat corporis
						optio illum tempora totam fugiat? Quaerat accusantium repudiandae
						doloremque! Eligendi blanditiis id velit voluptatibus maxime
						accusantium ipsum temporibus at quos obcaecati perferendis iste.
					</p>
					<br />
					<div class="preview">
						<img id="email_bottom_image" style="max-width: 100%" />
					</div>
					<div>
						<hr />
						<p>
							This message was sent to example@email.com by Alert Cascade
							Limited on behalf of Your Company.
						</p>
						<br />
						<p>
							This email is intended for the purposes of operational and/or
							emergency messaging only, and is not spam. If you have any
							questions about this email please contact us on custom
							{{ custom_branding.brand_email }} or call us on
							{{ custom_branding.brand_phone }}.
						</p>
						<br />
						<p>
							Alert Cascade Limited registered in England under number 09502529,
							with the Information Commissioner's Office under registration
							reference ZA189919. Registered office: Allia Future Business
							Centre, London Road, Peterborough, PE2 8AN
						</p>
						<br />
					</div>
					This email is intended for the purposes of operational and/or
					emergency messaging only, and is not spam. If you have any questions
					about this email please contact us on custom
					{{ custom_branding.brand_email }} or call us on
					{{ custom_branding.brand_phone }}.
					<br />
				</div>
			</div>
		</q-expansion-item>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: 'EmailPreview',
	computed: {
		...mapGetters(['custom_branding', 'email_top_image', 'email_bottom_image']),
	},
	watch: {
		email_top_image: {
			deep: true,
			handler(val) {
				let image = document.getElementById('email_top_image')
				if (!val) {
					image.src = ''
				} else {
					image.src = URL.createObjectURL(val)
				}
			},
		},
		email_bottom_image: {
			deep: true,
			handler(val) {
				let image = document.getElementById('email_bottom_image')
				if (!val) {
					image.src = ''
				} else {
					image.src = URL.createObjectURL(val)
				}
			},
		},
	},
	mounted() {
		if (this.custom_branding.email_top_image) {
			let image = document.getElementById('email_top_image')
			image.src = this.custom_branding.email_top_image
		}

		if (this.custom_branding.email_bottom_image) {
			let image = document.getElementById('email_bottom_image')
			image.src = this.custom_branding.email_bottom_image
		}
	},
}
</script>
