<template>
	<div
		v-if="
			current_CG.root_customer_group_permissions.includes(
				'Custom email branding'
			)
		"
	>
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			Settings
		</h1>
		<div class="settings-wrapper">
			<q-item tag="label" dense class="q-px-none">
				<q-item-section>
					<q-item-label>Custom export header</q-item-label>
				</q-item-section>
				<q-item-section avatar>
					<q-toggle v-model="enable_custom_export_header" />
				</q-item-section>
			</q-item>
			<q-input
				v-model="export_header"
				label="Export header"
				outlined
				bottom-slots
				:rules="export_header ? export_header_rules : []"
				:disable="enable_custom_export_header ? false : true"
				:error="hasError('export_header')"
				:error-message="branding_errors['export_header']"
			>
			</q-input>
		</div>

		<div class="settings-wrapper">
			<q-item tag="label" dense class="q-px-none">
				<q-item-section>
					<q-item-label>Custom welcome email</q-item-label>
				</q-item-section>
				<q-item-section avatar>
					<q-toggle v-model="enable_custom_welcome_email" />
				</q-item-section>
			</q-item>
			<q-input
				v-model="first_sentence"
				label="First sentence"
				outlined
				bottom-slots
				:disable="enable_custom_welcome_email ? false : true"
				:error="hasError('first_sentence')"
				:error-message="branding_errors['first_sentence']"
			>
			</q-input>
			<q-input
				v-model="reason_for_use"
				label="Reason for use"
				outlined
				bottom-slots
				:disable="enable_custom_welcome_email ? false : true"
				:error="hasError('reason_for_use')"
				:error-message="branding_errors['reason_for_use']"
			>
			</q-input>
		</div>

		<div class="settings-wrapper">
			<q-item tag="label" dense class="q-px-none">
				<q-item-section>
					<q-item-label>Enable email images</q-item-label>
				</q-item-section>
				<q-item-section avatar>
					<q-toggle v-model="enable_email_images" />
				</q-item-section>
			</q-item>
			<q-item tag="label" dense class="q-px-none">
				<q-item-section>
					<q-item-label>Email top image</q-item-label>
				</q-item-section>
				<q-item-section avatar>
					<q-toggle v-model="add_email_top_image" />
				</q-item-section>
			</q-item>

			<q-file
				v-model="email_top_image_new"
				color="primary"
				label="Upload an image. Max total upload size 5 Mb."
				class="q-mb-md"
				outlined
				accept=".png, .jpg"
				:disable="!add_email_top_image"
				:hint="email_top_image_hint"
				:error="hasError('email_top_image')"
				:error-message="branding_errors['email_top_image']"
			>
				<template v-slot:prepend>
					<q-icon name="file_upload" @click.stop />
				</template>
				<template v-if="email_top_image_new" v-slot:append>
					<q-icon
						name="cancel"
						@click.stop="cancelEmailTopImage"
						class="cursor-pointer"
					/>
				</template>
			</q-file>

			<q-item tag="label" dense class="q-px-none">
				<q-item-section>
					<q-item-label>Email bottom image</q-item-label>
				</q-item-section>
				<q-item-section avatar>
					<q-toggle v-model="add_email_bottom_image" />
				</q-item-section>
			</q-item>

			<q-file
				v-model="email_bottom_image_new"
				color="primary"
				label="Upload an image. Max total upload size 5 Mb."
				class="q-mb-md"
				outlined
				accept=".png, .jpg"
				:disable="!add_email_bottom_image"
				:hint="email_bottom_image_hint"
				:error="hasError('email_bottom_image')"
				:error-message="branding_errors['email_bottom_image']"
			>
				<template v-slot:prepend>
					<q-icon name="file_upload" @click.stop />
				</template>
				<template v-if="email_bottom_image_new" v-slot:append>
					<q-icon
						name="cancel"
						@click.stop="cancelEmailBottomImage"
						class="cursor-pointer"
					/>
				</template>
			</q-file>
		</div>

		<email-preview />
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import EmailPreview from '@/components/custom_branding/EmailPreview.vue'
export default {
	name: 'CustomBrandingSettings',
	components: {
		EmailPreview,
	},
	data() {
		return {
			export_header_rules: [
				(val) =>
					RegExp(/^[a-zA-Z0-9._\-()\"\',. ]*$/).test(val) ||
					`The field should be alphanumeric,allowed characters are ", \', (), -, and _. No additional special
					 characters can be included!`,
			],
			email_top_image_new: null,
			email_bottom_image_new: null,
		}
	},
	computed: {
		...mapGetters(['custom_branding', 'branding_errors', 'current_CG']),
		enable_custom_export_header: {
			get() {
				return this.custom_branding.enable_custom_export_header
			},
			set(val) {
				let custom_branding = JSON.parse(
					JSON.stringify(this.$store.getters.custom_branding)
				)
				custom_branding['enable_custom_export_header'] = val
				this.updateCustomBranding(custom_branding)
			},
		},
		export_header: {
			get() {
				return this.custom_branding.export_header
			},
			set(val) {
				let custom_branding = JSON.parse(
					JSON.stringify(this.$store.getters.custom_branding)
				)
				custom_branding['export_header'] = val
				this.updateCustomBranding(custom_branding)
			},
		},
		enable_custom_welcome_email: {
			get() {
				return this.custom_branding.enable_custom_welcome_email
			},
			set(val) {
				let custom_branding = JSON.parse(
					JSON.stringify(this.$store.getters.custom_branding)
				)
				custom_branding['enable_custom_welcome_email'] = val
				this.updateCustomBranding(custom_branding)
			},
		},
		first_sentence: {
			get() {
				return this.custom_branding.first_sentence
			},
			set(val) {
				let custom_branding = JSON.parse(
					JSON.stringify(this.$store.getters.custom_branding)
				)
				custom_branding['first_sentence'] = val
				this.updateCustomBranding(custom_branding)
			},
		},
		reason_for_use: {
			get() {
				return this.custom_branding.reason_for_use
			},
			set(val) {
				let custom_branding = JSON.parse(
					JSON.stringify(this.$store.getters.custom_branding)
				)
				custom_branding['reason_for_use'] = val
				this.updateCustomBranding(custom_branding)
			},
		},
		enable_email_images: {
			get() {
				return this.custom_branding.enable_email_images
			},
			set(val) {
				let custom_branding = JSON.parse(
					JSON.stringify(this.$store.getters.custom_branding)
				)
				custom_branding['enable_email_images'] = val
				this.updateCustomBranding(custom_branding)
			},
		},
		add_email_top_image: {
			get() {
				return this.custom_branding.add_email_top_image
			},
			set(val) {
				if (!val) {
					this.email_top_image_new = null
					this.updateCustomBrandEmailTopImage('')
				}
				let custom_branding = JSON.parse(
					JSON.stringify(this.$store.getters.custom_branding)
				)
				custom_branding['add_email_top_image'] = val
				this.updateCustomBranding(custom_branding)
			},
		},
		add_email_bottom_image: {
			get() {
				return this.custom_branding.add_email_bottom_image
			},
			set(val) {
				if (!val) {
					this.email_bottom_image_new = null
					this.updateCustomBrandEmailBottomImage('')
				}
				let custom_branding = JSON.parse(
					JSON.stringify(this.$store.getters.custom_branding)
				)
				custom_branding['add_email_bottom_image'] = val
				this.updateCustomBranding(custom_branding)
			},
		},
		email_top_image_hint() {
			if (this.custom_branding.email_top_image) {
				return `Current image: ${this.custom_branding.email_top_image}`
			}
			return `Current image:`
		},
		email_bottom_image_hint() {
			if (this.custom_branding.email_bottom_image) {
				return `Current image: ${this.custom_branding.email_bottom_image}`
			}
			return `Current image:`
		},
	},
	methods: {
		...mapActions([
			'updateCustomBranding',
			'updateCustomBrandEmailTopImage',
			'updateCustomBrandEmailBottomImage',
		]),
		cancelEmailTopImage() {
			this.email_top_image_new = null
		},
		cancelEmailBottomImage() {
			this.email_bottom_image_new = null
		},
		hasError(value) {
			return this.branding_errors[value] ? true : false
		},
	},
	watch: {
		email_top_image_new: {
			deep: true,
			handler(val) {
				this.updateCustomBrandEmailTopImage(val)
			},
		},
		email_bottom_image_new: {
			deep: true,
			handler(val) {
				this.updateCustomBrandEmailBottomImage(val)
			},
		},
	},
}
</script>
